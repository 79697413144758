@import './Styles/variables';
@import '~bootstrap/scss/bootstrap';
@import '~react-day-picker/lib/style.css';
@import './Styles/index.scss';

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-thumb {
  background: $ibf-color;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

#hs-eu-cookie-confirmation-inner {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
  @media (max-width: 998px) {
    flex-direction: column;
  }
}

#hs-eu-policy-wording {
  margin: 0 !important;
}

#hs-eu-policy-wording p {
  margin: 0 !important;
}

#hs-eu-cookie-confirmation {
  width: 50vw !important;
  //right: 0 !important;
  left: 25vw !important;
}

.selector-no-border-radius {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

.border-top-gray-color {
  border-top-color: #dee2e6;
}

.border-right-gray-color {
  border-right-color: #dee2e6;
}
.border-bottom-gray-color {
  border-bottom-color: #dee2e6;
}

.detail-scroll {
  left: 45vw;
  color: #848484;
}

.custom_span_input {
  border: solid 1px #e7e2df;
  background: #ccc;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  display: block;
}

.color-custom-label-green {
  color: #004d40;
}
