
.contFiltersMainD {
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  top: 0;
  left: 0;
  padding: 20px;
  transform: translateX(-1000px);
  transition: transform 0.6s ease-in;

  .btnCloseFilters {
    transform: rotate(45deg);
    border-radius: 50%;
    background-color: white;
    border: 0.2px solid rgba(#000, 0.5);
    margin-bottom: 15px;

    svg path {
        fill: rgba(#000, 0.5);
      }
  }
}

.showFilter {
  transform: translateX(0);
}

.contFiltersD {
  border-radius: 3px;
  border: 0.5px solid rgba(#000, 0.15);
  margin-bottom: 10px;
  // height: 210px;
  // overflow-y: auto;

  .inputFiltersD {
    position: relative;

    svg {
      position: absolute;
      top: 48%;
      left: 1%;
      transform: translateY(-50%);
    }

    input {
      outline: none;
      border: none;
      border-bottom: 0.5px solid rgba(#000, 0.15);
      width: 100%;
      padding: 5px 0 5px 28px;
      // font-size: 15px;
    }
  }

  .ListFiltersD {
    max-height: 200px;
    overflow-y: auto;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      padding: 10px 0 10px 7px;

      li {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
        align-items: center;

        label {
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .labelD {
          display: flex;
          gap: 5px;
          align-items: center;

          div {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 2px 2px 174px -33px rgba(0, 0, 0, 0.75);
            display: flex;
            justify-content: center;
            align-items: center;

            div {
              width: 13px;
              height: 13px;
              border-radius: 50%;
            }
          }
        }
      }

      .v2 {
        padding: 5px 0 5px 7px;
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
}


@media (min-width: 960px) {

  .contFiltersMainD {
    all: unset;

    .btnCloseFilters {
      display: none;
    }
  }

  .contFiltersD {
    border-radius: 3px;
    border: 0.5px solid rgba(#000, 0.15);
    margin-bottom: 10px;
    // height: 210px;
    // overflow-y: auto;
  
    .inputFiltersD {
      position: relative;
  
      svg {
        position: absolute;
        top: 48%;
        left: 2%;
        transform: translateY(-50%);
      }
  
      input {
        outline: none;
        border: none;
        border-bottom: 0.5px solid rgba(#000, 0.15);
        width: 100%;
        padding: 5px 0 5px 28px;
        // font-size: 15px;
      }
    }
  
    .ListFiltersD {
      max-height: 200px;
      overflow-y: auto;
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 10px 0 10px 7px;
  
        li {
          display: flex;
          gap: 10px;
          margin-bottom: 15px;
          align-items: center;
  
          label {
            margin: 0;
          }
  
          &:last-child {
            margin-bottom: 0;
          }
  
          .labelD {
            display: flex;
            gap: 5px;
            align-items: center;
  
            div {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: white;
              box-shadow: 2px 2px 174px -33px rgba(0, 0, 0, 0.75);
              display: flex;
              justify-content: center;
              align-items: center;
  
              div {
                width: 13px;
                height: 13px;
                border-radius: 50%;
              }
            }
          }
        }
  
        .v2 {
          padding: 5px 0 5px 7px;
          li {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
} 
