.w-40 {
  width: 40% !important;
}

.w-lg-50 {
  @media (min-width: 992px) {
    width: 50%;
  }
}

.w-lg-75 {
  @media (min-width: 992px) {
    width: 75%;
  }
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80%;
}

.color-gray {
  color: $ibf-color-gray;
}
.color-gray-text {
  color: #6c757d;
}
.background-primary {
  background: $ibf-color;
}

.background-orange {
  background-color: orange;
}

.background-green {
  background-color: green;
}

.color-green {
  color: green;
}

.color-blue {
  color: blue;
}
.color-gray-light {
  color: #c5c5c5;
}

.color-black {
  color: black;
}

.background-yellow {
  background-color: $ibf-color-yellow;
}

.background-white {
  background-color: white;
}

.background-inherit {
  background-color: inherit;
}

.background-gray {
  background-color: #f2f2f2;
}
.background-lightgray {
  background-color: #d7d7d7;
}

.color-red {
  color: red;
}

.color-orange {
  color: orange;
}

.background-red {
  background-color: #ffecea;
}

.z-index {
  z-index: 1024;
}

.text-white {
  color: white;
}

.h-85 {
  height: 85%;
}

.h-80 {
  height: 80%;
}

.h-75 {
  height: 75%;
}
.h-25 {
  height: 25%;
}

.h-20 {
  height: 20%;
}

.h-15 {
  height: 15%;
}
.border-bottom-dashed {
  border-bottom: 1px dashed #000000;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  line-height: 1.625rem;
}
.scroll-behavior {
  scroll-behavior: smooth;
}
.color-green-light {
  color: $ibf-progress-green;
}

.ibf-container-usp {
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.dotted-style-bottom {
  border-bottom-style: dotted;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  display: flex;
  align-items: center;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 2rem;
  color: #3c3c3f;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 2rem;
  color: #3c3c3f;
}

.control-custom {
  width: 2%;
  height: 50px;
  background-color: #e7e2df;
  border-radius: 3px;
  color: #000000;
}
.control-custom::after {
  content: '';
  display: block;
  border-right: 2px;
  border-color: #e7e2df;
}

.custom-delivery {
  background-color: #c7e7f7;
  border: 2px solid #46aee4;
  height: 84px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 10px;
  @media (max-width: 580px) {
    height: 94px;
  }
}

.ibf-dropdown-size {
  width: 330px;
}

.max-height-500 {
  max-height: 500px;
}
.size-sppiner {
  width: 1.2rem;
  height: 1.2rem;
}

//
.content-available {
  width: 25%;
  @media (max-width: 580px) {
    width: 45%;
  }
}

.container-available {
  border-color: '#e7e2df';
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  height: 74px;
  max-width: 1000px;
  @media (max-width: 580px) {
    height: 84px;
    font-size: 13px;
  }
}

.angle-available-right {
  height: 74px;
  line-height: 74px;
  background: transparent;
  border: none;
  border-right: 1px solid #e7e2df;
  padding: 0;
  @media (max-width: 580px) {
    height: 84px;
    line-height: 84px;
  }
}
.angle-available-left {
  height: 74px;
  line-height: 74px;
  background: transparent;
  border: none;
  border-left: 1px solid #e7e2df;
  padding: 0;
  @media (max-width: 580px) {
    height: 84px;
    line-height: 84px;
  }
}

.skeleton-custom-size {
  height: 74px;
  @media (max-width: 580px) {
    height: 84px;
  }
}

.cursor-auto {
  cursor: auto;
}

.background-transparent {
  background-color: transparent;
}

.size-growers {
  width: 55px;
  text-align: center;
}
.color-soft-gray {
  color: #3e3d3a;
}
.content-available {
  @media (min-width: 992px) {
    width: 50rem;
  }
}

.height-50 {
  height: 50px;
}

.ibf-color--dark-green {
  color: $ibf-color-green;
}
.right-0 {
  right: 0;
}

.border-top-bottom {
  border-top: 1px solid #b0afa6;
  border-bottom: 1px solid #b0afa6;
}
.border-top-gray {
  border-top: 1px solid #9c9c9c;
}
.close--button {
  right: -7px;
  z-index: 1;
  top: -23px;
  font-size: 29px;
}

.ibf-container-message-reminder {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  background-color: $ibf-color-green;
}

.ibf-container-message-reminder:before {
  content: '';
  position: absolute;
  bottom: -25px;
  width: 0;
  right: 0;
  height: 0;
  margin-right: 80px;
  border-width: 25px 25px 0;
  border-style: solid;
  border-color: $ibf-color-green transparent transparent;
  @media (min-width: 320px) and (max-width: 600px) {
    left: 80%;
  }
}

.border-radius-t-right {
  border-radius: 3px;
}

.max-width-1000 {
  max-width: 1000px;
}
.height-300 {
  max-height: 300px;
}

.list-login {
  position: relative;
  list-style: none;
}

.image_plataform {
  //
  min-width: 23rem;
  @media (min-width: 320px) and (max-width: 1200px) {
    outline: 4px solid #169bd5;
  }
}

.image-farm-grower {
  position: absolute;
  bottom: -3.2rem;
  right: -0.3rem;
  min-width: 13.5rem;
  @media (min-width: 320px) and (max-width: 1200px) {
    bottom: -3rem;
    right: -5.3rem;
    min-width: 13.5rem;
  }
}
.border-radius-t-right {
  border-radius: 3px;
}
.w-90 {
  width: 90%;
}

.width-box-deleiting {
  @media (min-width: 992px) {
    width: 45.25rem;
  }
}

.list-login {
  position: relative;
  list-style: none;
}

.image_plataform {
  //
  min-width: 23rem;
  @media (min-width: 320px) and (max-width: 1200px) {
    outline: 4px solid #169bd5;
  }
}

.image-farm-grower {
  position: absolute;
  bottom: -3.2rem;
  right: -5.2rem;
  min-width: 13.5rem;
  @media (min-width: 320px) and (max-width: 1200px) {
    bottom: -3rem;
    right: -5.3rem;
    min-width: 13.5rem;
  }
}
